<template>
  <div><dashboard :data="data" :busy="isLoading" /></div>
</template>

<script>
import { BCard, BLink, BCardText } from "bootstrap-vue";
import Dashboard from "@/components/dashboard/index.vue";
export default {
  components: {
    Dashboard,
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
      isLoading: false,
      data: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      try {
        const request = await this.$http.get("admin/statistics");
        if (request.status === 200 || request.status === 201) {
          this.data = request.data.data;
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
