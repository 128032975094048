<template>
  <div>
    <section id="dashboard-ecommerce">
      <b-row class="match-height">
        <b-col cols="12">
          <ecommerce-statistics :data="statisticsItems" />
          <overlay-component :is-loading="busy" />
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import Ecommerce from "./ecommerce/Ecommerce.vue";
import OverlayComponent from "@/components/shared/OverlayComponent/index.vue";
import { BCol, BRow } from "bootstrap-vue";
import EcommerceStatistics from "@/components/dashboard/ecommerce/EcommerceStatistics.vue";

export default {
  name: "Dashboard",
  props: ["busy", "data"],
  components: {
    EcommerceStatistics,
    BCol,
    BRow,
    OverlayComponent,
    Ecommerce,
  },
  data() {
    return {
      statisticsItems: [
        {
          icon: "UsersIcon",
          color: "light-primary",
          label: "students",
          title: "",
          subtitle: this.$t("statistics.students"),
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "UsersIcon",
          color: "light-info",
          label: "Teachers",

          title: "",
          subtitle: this.$t("statistics.Teachers"),
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "BookIcon",
          color: "light-danger",
          title: "",
          label: "summer_Courses",
          subtitle: this.$t("statistics.summer_courses"),
          customClass: "mb-2 mb-sm-0",
        },
        {
          icon: "BookIcon",
          label: "academic Courses",
          color: "light-success",
          title: "",
          subtitle: this.$t("statistics.academic_courses"),
          customClass: "",
        },
        {
          icon: "BookIcon",
          label: "revenue",
          color: "light-success",
          title: "",
          subtitle: this.$t("statistics.revenue"),
          customClass: "mt-1",
        },
      ],
    };
  },

  methods: {
    handelPresentingData(data) {
      const dataKeys = Object.keys(data);
      dataKeys.forEach((ele) => {
        this.statisticsItems.filter((item) => item.label === ele)[0].title =
          data[ele];
      });
    },
  },
  watch: {
    data(newVal) {
      this.handelPresentingData(newVal);
    },
  },
};
</script>

<style lang="scss">
@import "./_index";
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
